exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-automatismo-js": () => import("./../../../src/pages/automatismo.js" /* webpackChunkName: "component---src-pages-automatismo-js" */),
  "component---src-pages-buenas-practicas-index-js": () => import("./../../../src/pages/buenas-practicas/index.js" /* webpackChunkName: "component---src-pages-buenas-practicas-index-js" */),
  "component---src-pages-buenas-practicas-politicadegestion-js": () => import("./../../../src/pages/buenas-practicas/politicadegestion.js" /* webpackChunkName: "component---src-pages-buenas-practicas-politicadegestion-js" */),
  "component---src-pages-buenaspracticas-js": () => import("./../../../src/pages/buenaspracticas.js" /* webpackChunkName: "component---src-pages-buenaspracticas-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-energia-js": () => import("./../../../src/pages/energia.js" /* webpackChunkName: "component---src-pages-energia-js" */),
  "component---src-pages-fabrica-bandeja-escalera-js": () => import("./../../../src/pages/fabrica/bandeja-escalera.js" /* webpackChunkName: "component---src-pages-fabrica-bandeja-escalera-js" */),
  "component---src-pages-fabrica-bandeja-perforada-js": () => import("./../../../src/pages/fabrica/bandeja-perforada.js" /* webpackChunkName: "component---src-pages-fabrica-bandeja-perforada-js" */),
  "component---src-pages-fabrica-celda-mt-13-2-kv-js": () => import("./../../../src/pages/fabrica/celda-mt-13-2kv.js" /* webpackChunkName: "component---src-pages-fabrica-celda-mt-13-2-kv-js" */),
  "component---src-pages-fabrica-celda-mt-33-kv-js": () => import("./../../../src/pages/fabrica/celda-mt-33kv.js" /* webpackChunkName: "component---src-pages-fabrica-celda-mt-33-kv-js" */),
  "component---src-pages-fabrica-electrocentros-js": () => import("./../../../src/pages/fabrica/electrocentros.js" /* webpackChunkName: "component---src-pages-fabrica-electrocentros-js" */),
  "component---src-pages-fabrica-iluminacion-columnas-js": () => import("./../../../src/pages/fabrica/iluminacion-columnas.js" /* webpackChunkName: "component---src-pages-fabrica-iluminacion-columnas-js" */),
  "component---src-pages-fabrica-iluminacion-torres-js": () => import("./../../../src/pages/fabrica/iluminacion-torres.js" /* webpackChunkName: "component---src-pages-fabrica-iluminacion-torres-js" */),
  "component---src-pages-fabrica-index-js": () => import("./../../../src/pages/fabrica/index.js" /* webpackChunkName: "component---src-pages-fabrica-index-js" */),
  "component---src-pages-fabrica-tablero-bt-ccm-js": () => import("./../../../src/pages/fabrica/tablero-bt-ccm.js" /* webpackChunkName: "component---src-pages-fabrica-tablero-bt-ccm-js" */),
  "component---src-pages-fabrica-tablero-bt-tgbt-js": () => import("./../../../src/pages/fabrica/tablero-bt-tgbt.js" /* webpackChunkName: "component---src-pages-fabrica-tablero-bt-tgbt-js" */),
  "component---src-pages-gateway-js": () => import("./../../../src/pages/gateway.js" /* webpackChunkName: "component---src-pages-gateway-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industria-js": () => import("./../../../src/pages/industria.js" /* webpackChunkName: "component---src-pages-industria-js" */),
  "component---src-pages-infraestructura-js": () => import("./../../../src/pages/infraestructura.js" /* webpackChunkName: "component---src-pages-infraestructura-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-novedades-index-js": () => import("./../../../src/pages/novedades/index.js" /* webpackChunkName: "component---src-pages-novedades-index-js" */),
  "component---src-pages-novedades-mdx-slug-js": () => import("./../../../src/pages/novedades/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-novedades-mdx-slug-js" */),
  "component---src-pages-politicasdecalidad-js": () => import("./../../../src/pages/politicasdecalidad.js" /* webpackChunkName: "component---src-pages-politicasdecalidad-js" */),
  "component---src-pages-prueba-js": () => import("./../../../src/pages/prueba.js" /* webpackChunkName: "component---src-pages-prueba-js" */),
  "component---src-pages-recursos-humanos-js": () => import("./../../../src/pages/recursos-humanos.js" /* webpackChunkName: "component---src-pages-recursos-humanos-js" */),
  "component---src-pages-renovable-js": () => import("./../../../src/pages/renovable.js" /* webpackChunkName: "component---src-pages-renovable-js" */),
  "component---src-pages-saneamiento-js": () => import("./../../../src/pages/saneamiento.js" /* webpackChunkName: "component---src-pages-saneamiento-js" */),
  "component---src-pages-seccion-en-desarrollo-js": () => import("./../../../src/pages/seccion-en-desarrollo.js" /* webpackChunkName: "component---src-pages-seccion-en-desarrollo-js" */),
  "component---src-pages-ventas-js": () => import("./../../../src/pages/ventas.js" /* webpackChunkName: "component---src-pages-ventas-js" */)
}

